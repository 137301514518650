import React from "react";
import Dropdown from "@mui/joy/Dropdown";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";

export default function StaffName(
  {
    elementName,
    menuOptions,
    highlighted,
    isSLine,
  },
) {
  const backgroundColorBase = isSLine ? "#d9d9d9" : "#f0f0f0";
  return (
    <>
      <Dropdown>
        <MenuButton
          sx={{
            backgroundColor: highlighted ? "#fef7c3" : backgroundColorBase,
            overflow: "hidden",
            // fontSize: "clamp(.75rem, 1.25vw, 1rem)",
          }}
        >
          {elementName}
        </MenuButton>
        <Menu placement="right-start">
          {menuOptions.map((menuOption) => {
            return (
              <MenuItem
                key={menuOption.label}
                onClick={() => menuOption.action()}
              >
                {menuOption.label}
              </MenuItem>
            );
          })}
        </Menu>
      </Dropdown>
    </>
  );
}

